
import { VersionNumber, useVersionStore } from '@/stores/version';
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import BpVersionNumberEditorVue from '../../../components/version/BpVersionNumberEditor.vue';

export default defineComponent({
  name: "version-number-editor",
  components: {
    BpVersionNumberEditor: BpVersionNumberEditorVue,
  },
  setup() {
    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();

    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const store = useVersionStore();

    const versionNumber = ref<VersionNumber>();

    function loadProduct() {
      if (router.currentRoute.value.name && (router.currentRoute.value.name as string).endsWith('edit')) {
        const doc = store.getById(router.currentRoute.value.params.id as string)
        versionNumber.value = doc as VersionNumber;
      }
    }

    watch(() => store.isLoading(), () => {
      if (!store.loaded || store.isLoading()) { return }
      loadProduct()
    }, { immediate: true });

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------
    return {
      versionNumber,
      store,
    };
  }
})
